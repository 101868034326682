import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class Service {

  httpOptions: { headers: any; observe: any; } = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    observe: 'response'
  };

  public appIsOnline: Observable<boolean>;

  constructor(
    private http: HttpClient,
    private platform: Platform,
  ) {
    this.monitoramentoConectividade();
   }

  public monitoramentoConectividade() {
    if (this.platform.is('android')) {
      if (!window || !navigator || !('onLine' in navigator)) return;
      this.appIsOnline = merge(
        of(null),
        fromEvent(window, 'online'),
        fromEvent(window, 'offline')
      ).pipe(map(() => navigator.onLine))
    }
  }

  cadastrar(request: any) {
    return this.http.post(`${environment.baseServiceUrl}PesquisaSatisfacao`, request);
  }

  listar() {
    return this.http.get(`${environment.baseServiceUrl}PesquisaSatisfacao`);
  }
}
