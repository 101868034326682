import { Platform, ToastController } from '@ionic/angular';
import { Service } from './service.service';
import { Observable, Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { StorageService } from './storage.service';
import { PesquisaSatisfacao } from './pesquisa-satisfacao/pesquisa-satisfacao.model';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy{

  private appIsOnlineSubscription: Subscription;

  constructor(
    private platform: Platform,
    public service: Service,
    private storageService: StorageService,
    private toastController: ToastController
  ) {
    this.enviarPesquisasSatisfacao();
  }

  async enviarPesquisasSatisfacao() {
    if (this.platform.is('android')) {
      const context = this;
      this.service.appIsOnline.subscribe((statusConexao) => {
        if (statusConexao) {
          setInterval(async async => {
            let executarEnvioPesquisaSatisfacao = await context.storageService.get("enviarPesquisaSatisfacao");
            if (executarEnvioPesquisaSatisfacao == true) {
              let respostas: PesquisaSatisfacao[];
              respostas = await context.storageService.get("respostasPesquisaSatisfacao");
              if (respostas != undefined) {
                let respostasParaEnvio = respostas.filter(opcoes =>
                                          opcoes.enviado == false);
                if (respostasParaEnvio.length > 0) {
                  context.service.cadastrar(respostasParaEnvio).subscribe(async (data: any) => {
                    for(let resposta of respostas) {
                      resposta.enviado = true;
                    }
                    console.log(respostasParaEnvio.length + " enviadas!")
                    context.storageService.set("respostasPesquisaSatisfacao", respostas);
                  }, async (error: any) => {
                    //Retorna erro da API
                    console.log("Erro ao enviar: " + error.error.mensagemErro)
                  });
                } else {
                  console.log("Não existem pesquisas de satisfação para enviar!")
                }
              } else {
                console.log("Não existem pesquisas de satisfação para enviar!")
              }
            } else {
              console.log("Aguardando finalização de pesquisa em andamento!")
            }
          }, 10000);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.appIsOnlineSubscription.unsubscribe();
  }
}


